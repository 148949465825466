import * as React from 'react'
import { AuthProvider } from 'src/contexts/auth-context'
import WithSwrContext from './WithSwrContext'

export const withAuthContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WithSwrContext>
        <WithAuthContext>
          <Component {...props} />
        </WithAuthContext>
      </WithSwrContext>
    )

export default function WithAuthContext({
  children,
}: {
  children: React.ReactNode
}) {
  return <AuthProvider>{children}</AuthProvider>
}
