import * as React from 'react'
import { SWRConfig } from 'swr'
import httpClient from 'src/utils/helpers/http'

export const withSwrContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WithSwrContext>
        <Component {...props} />
      </WithSwrContext>
    )

export default function WithSwrContext({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <SWRConfig
      value={{
        fetcher: httpClient,
        revalidateOnFocus: false,
        onError: (err) => {
          console.error(err)
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}
